import React, { FC, ReactNode, useRef, useState } from "react";
import clsx from "clsx";

import { DateSelector } from "components/date-selector/date-selector";

import styles from "./scroll-layout.module.scss";
import { TopMenu } from "components/top-menu/top-menu";
import { AddData } from "components/add-data/add-data";
import { LayoutType } from "types/utils";

type Props = {
  top: ReactNode;
  content: ReactNode;
  topHeader?: string;
  contentHeader?: string;
  isCyclist?: boolean;
  topMenuItems?: LayoutType;
  contentClassName?: string;
  showAdditonalComps?: boolean;
  showCalendarComp?: boolean;
  contentCustomWrapper?:string
};

export const ScrollLayout: FC<Props> = ({
  topHeader,
  top,
  contentHeader,
  content,
  isCyclist,
  topMenuItems,
  contentClassName,
  showAdditonalComps = true,
  showCalendarComp = true,
  contentCustomWrapper,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [showCalendar, setShowCalendar] = useState(showCalendarComp);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const onlyContentVisible =
      e.currentTarget?.scrollTop + e.currentTarget?.offsetTop + 1 >
      (contentRef.current?.offsetTop || 0);
    setShowCalendar(!onlyContentVisible);
  };

  return (
    <>
      {(!isCyclist || Boolean(topHeader)) && (
        <>
          {!isCyclist && (
            <div className={styles.topMenu}>
              <TopMenu menuItems={topMenuItems} />
              {showAdditonalComps && (
                <div className={styles.rightMost}>{!isCyclist && <AddData />}</div>
              )}
            </div>
          )}
          <div className={styles.topHeader}>
            {showCalendar && (
              <div className={styles.topHeaderCls}>
                <h2 className={styles.title}>{topHeader}</h2>
                {isCyclist && <AddData />}
              </div>
            )}
            {showCalendarComp && !showCalendar && (
              <>
                {contentHeader && <h2 className={styles.title}>{contentHeader}</h2>}
                <DateSelector />
              </>
            )}
          </div>
        </>
      )}
      <div className={styles.content} onScroll={handleScroll}>
        {top}
        <div className={clsx(styles.contentArea, contentClassName)}>
          {(contentHeader || showCalendarComp) && <div className={styles.contentHeader}>
            {contentHeader && <h2 className={styles.title}>{contentHeader}</h2>}
            {showCalendarComp && <DateSelector />}
          </div>}
          <div className={clsx(styles.contentWrapper, contentCustomWrapper)} ref={contentRef}>
           {content}
          </div>
        </div>
      </div>
    </>
  );
};
