import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as HeartRate } from "images/heart-rate.svg";
import { ReactComponent as BarChart } from "images/bar-chart.svg";
import styles from "./top-menu.module.scss";
import { LayoutType } from "types/utils";

const defaultMenuItems: LayoutType = [
  {
    to: "/performance",
    icon: BarChart,
    text: "Performance",
  },
  {
    to: "/wellness",
    icon: HeartRate,
    text: "Wellness",
  },
  {
    to: "/health",
    icon: BarChart,
    text: "Health",
  },
];

export const TopMenu = ({ menuItems = defaultMenuItems }) => {
  return (
    <div className={styles.menubar}>
      {menuItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.to}
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          {/* {item.icon && <item.icon className={styles.icon} />} */}
          <span className={styles.linkText}>{item.text}</span>
        </NavLink>
      ))}
    </div>
  );
};
