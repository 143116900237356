import { differenceInYears, format, parse } from "date-fns";
import { CommonProps, OptionTypeBase } from "react-windowed-select";
import { ReactNode } from "react";
import { Range } from "react-date-range";
import { DateGroupBy, DateRange, FlavorType, TimeOptions } from "enums";
import { ClientConfig } from "clients/types";
import { Athlete, PeriodType, QueryParams, SelectedPeriod } from "types";

const apiDateFormat = "yyyy-MM-dd";
const localDateFormat = "dd/MM/yyyy";

const baseValue = 20;

export function pdFormatTime(seconds: number): string {
  if (seconds >= 3600) {
    return Math.round(seconds / 3600) + "h";
  } else if (seconds >= 60) {
    return Math.round(seconds / 60) + "m";
  } else {
    return Math.round(seconds) + "s";
  }
}

const STORAGE_LIMIT = 3;
const KEY_PREFIXES = [
  "athletePowerDerivative-labels",
  "athleteFatigueResistance-labels",
];

const matchesKeyPrefix = (key: string) => {
  return KEY_PREFIXES.some((prefix) => key.startsWith(prefix));
};

const clearKeysWithPrefixes = () => {
  const keysWithPrefix = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && matchesKeyPrefix(key)) {
      const itemStr = localStorage.getItem(key);
      if (itemStr) {
        const item = JSON.parse(itemStr);
        keysWithPrefix.push({ key, expiry: item.expiry });
      }
    }
  }

  keysWithPrefix.sort((a, b) => a.expiry - b.expiry);

  while (keysWithPrefix.length > STORAGE_LIMIT) {
    const keyToRemove = keysWithPrefix.shift();
    if (keyToRemove) {
      localStorage.removeItem(keyToRemove.key);
    }
  }
};

export const setWithExpiry = (key: string, value: string[], ttl: number) => {
  clearKeysWithPrefixes();
  const item = {
    value: value,
    expiry: Date.now() + ttl,
  };
  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    console.error("Error saving to localStorage", e);
  }
};

export const getWithExpiry = (key: string) => {
  clearKeysWithPrefixes();

  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = Date.now();
  if (now > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export function formatTimeLabels(labelArray: string[]) {
  return labelArray.map((label) => {
    const unit = label[label.length - 1];
    const value = parseInt(label.slice(0, -1));

    let hours = 0,
      minutes = 0,
      seconds = 0;

    if (unit === "s") {
      seconds = value;
    } else if (unit === "m") {
      minutes = value;
    } else if (unit === "h") {
      hours = value;
    }

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  });
}

export function generateSmoothCurve(
  data: number[],
  maxWindowSize = 1
): [number, number][] {
  const smoothData = data.map((_, index) => {
    const windowSize = Math.max(maxWindowSize, Math.floor(index / 3) + 3);
    const start = Math.max(0, index - Math.floor(windowSize / 2));
    const end = Math.min(data.length, index + Math.floor(windowSize / 2) + 1);

    let sum = 0;
    for (let i = start; i < end; i++) {
      sum += data[i];
    }
    const average = sum / (end - start);

    return [index, average];
  });

  return smoothData as [number, number][];
}

export const formatTime: (seconds: number) => string = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (seconds < 60) {
    return seconds + "s";
  } else if (hours > 0) {
    if (remainingMinutes === 0 && remainingSeconds === 0) {
      return hours + "h";
    } else if (remainingSeconds === 0) {
      return hours + "h " + remainingMinutes + "m";
    } else {
      return hours + "h " + remainingMinutes + "m " + remainingSeconds + "s";
    }
  } else if (remainingSeconds === 0) {
    return minutes + "m";
  } else {
    return minutes + "m " + remainingSeconds + "s";
  }
};

export const getCorrectDescendingSeriesWithZeroAsObject = (
  arr: number[]
): { value: number }[] => {
  const result: { value: number }[] = [];
  let prev = Infinity; // Initialize prev with positive infinity
  let hasZero = false; // Flag to track if a zero was inserted

  for (let i = 0; i < arr.length; i++) {
    const current = arr[i];
    const value = prev > current ? current : i === 0 ? current : 0;
    result.push({ value }); // Push object with value

    // Update prev and hasZero only if value is not zero
    if (value !== 0) {
      prev = current;
      hasZero = false;
    } else if (hasZero) {
      // If a zero was already inserted, maintain it
      result[result.length - 1].value = 0;
    }
  }

  return result;
};

export const generateTimestamps = (length = 0, interval = 1000): number[] => {
  const timestamps: number[] = [];
  const now = new Date();

  // Set time components to 0 for current date in your timezone
  now.setHours(0, 0, 0, 0);

  for (let i = 0; i < length; i++) {
    const timestamp = new Date(now.getTime() + i * interval);
    timestamps.push(timestamp.getTime());
  }

  return timestamps;
};

export const getStartAndEndDateOfWeek = (
  previousWeek = false
): { start: Date; end: Date } => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  let diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

  if (previousWeek) {
    diff -= 7;
  }

  const startOfWeek = new Date(today.setDate(diff));
  startOfWeek.setHours(0, 0, 0, 0);

  let endOfWeek: Date;

  if (!previousWeek) {
    endOfWeek = new Date();
  } else {
    endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
  }

  endOfWeek.setHours(23, 59, 59, 999);

  return {
    start: startOfWeek,
    end: endOfWeek,
  };
};

export const getStartAndEndDate = (
  periodType: PeriodType,
  selectedPeriod?: SelectedPeriod
): { startDate: Date; endDate: Date } => {
  let startDate: Date;
  let endDate = new Date();

  const getPreviousMonday = (date: Date) => {
    const day = date.getDay();
    const prevMonday = new Date(date);
    prevMonday.setDate(date.getDate() - day + (day === 0 ? -6 : 1));
    prevMonday.setHours(0, 0, 0, 0);
    return prevMonday;
  };

  switch (periodType) {
    case TimeOptions.thisWeek:
      startDate = getPreviousMonday(new Date());
      if (new Date().getDay() === 0) {
        endDate.setHours(23, 59, 59, 999);
      }
      break;
    case TimeOptions.LastWeek:
      {
        const { start, end } = getStartAndEndDateOfWeek(true);
        startDate = start;
        endDate = end;
      }
      break;
    case TimeOptions.LastThirtyDays:
      endDate = new Date();
      startDate = new Date(endDate.getTime() - DateRange.Month);
      break;
    case TimeOptions.LastSixtyDays:
      endDate = new Date();
      startDate = new Date(endDate.getTime() - DateRange.TwoMonth);
      break;
    case TimeOptions.LastNinetyDays:
      endDate = new Date();
      startDate = new Date(endDate.getTime() - DateRange.ThreeMonth);
      break;
    case TimeOptions.LastThreeHundredSixtyFiveDays:
      endDate = new Date();
      startDate = new Date(endDate.getTime() - DateRange.Year);
      break;
    default:
      if (
        selectedPeriod &&
        "startDate" in selectedPeriod &&
        "endDate" in selectedPeriod
      ) {
        startDate = (selectedPeriod as Range).startDate as Date;
        endDate = (selectedPeriod as Range).endDate as Date;
      } else {
        throw new Error("Invalid periodType or selectedPeriod");
      }
  }
  return {
    startDate,
    endDate,
  };
};

export const calculateDateRange = (
  dateDuration: DateRange
): { startDate: Date; endDate: Date } => {
  let startDate: Date;
  let endDate = new Date();

  switch (dateDuration) {
    case DateRange.Week:
      {
        const { start, end } = getStartAndEndDateOfWeek(true);
        startDate = start;
        endDate = end;
      }
      break;
    case DateRange.FourDays:
    case DateRange.TwoWeeks:
    case DateRange.EightDays:
    case DateRange.Month:
    case DateRange.TwoMonth:
    case DateRange.ThreeMonth:
    case DateRange.Year:
      endDate = new Date();
      startDate = new Date(endDate.getTime() - dateDuration);
      break;
    default:
      throw new Error("Invalid periodType");
  }
  return {
    startDate,
    endDate,
  };
};

export const formatDate: (date: Date) => string = (date) =>
  format(date, localDateFormat);

export const formatDateAPI: (date: Date) => string = (date) =>
  format(date, apiDateFormat);

export const parseDate: (input: string) => Date = (input) =>
  parse(input, apiDateFormat, new Date());

export const getAge: (date: Date) => number = (date) =>
  differenceInYears(new Date(), date);
export type Id = string | number;

export const stringifyDateRange = (dateRange?: DateRange) => {
  switch (dateRange) {
    case DateRange.Year:
      return "WEEK";
    case DateRange.ThreeMonth:
    case DateRange.TwoMonth:
    case DateRange.Month:
    case DateRange.Week:
    default:
      return "DAY";
  }
};

export const getQueryParams: (
  athleteIds?: number[],
  dateRange?: DateRange,
  currentDateUnit?: DateGroupBy
) => string = (athleteIds, dateRange, currentDateUnit) => {
  return `unit=${currentDateUnit ?? stringifyDateRange(dateRange)}&athleteIds=${
    athleteIds?.join("&athleteIds=") || ""
  }`;
};

export const getURIQueryParams = (): QueryParams => {
  // Get the query string, remove the leading '?', and split into an array of parameters
  const paramsArray: string[] = window.location.search
    .replace("?", "")
    .split("&");

  // Reduce the array to an object
  const paramsObject = paramsArray.reduce<{ [key: string]: string }>(
    (result, param) => {
      // Split each parameter into key and value
      const [key, value] = param.split("=");

      // Decode the value and add the key-value pair to the result object
      if (key && value) {
        result[key] = decodeURIComponent(value);
      }

      return result;
    },
    {}
  );

  // Ensure the object has the required keys with default values if not present
  return {
    otp: paramsObject.otp || "",
    email: paramsObject.email || "",
    team: paramsObject.team || "",
  };
};

export const getURLQueryParams = (): Record<string, string> => {
  const queryParams: Record<string, string> = {};
  const searchParams = new URLSearchParams(window.location.search);

  // Convert the IterableIterator to an array for wider compatibility
  const paramsArray = Array.from(searchParams.entries()); // This is the key adjustment

  for (const [key, value] of paramsArray) {
    queryParams[key] = value;
  }

  return queryParams;
};

export const getITrimpTssParams: (athleteIds?: number[]) => string = (
  athleteIds
) => {
  return `unit=DAY&athleteIds=${athleteIds?.join("&athleteIds=") || ""}`;
};

export function convertHoursToHMS(hours: number) {
  const h = Math.floor(hours);
  const minutes = (hours - h) * 60;
  const m = Math.floor(minutes);
  const seconds = (minutes - m) * 60;
  const s = Math.round(seconds);

  // Pad with zeros to ensure two digits for hours, minutes, and seconds
  const hh = h.toString().padStart(2, "0");
  const mm = m.toString().padStart(2, "0");
  const ss = s.toString().padStart(2, "0");

  return `${hh}:${mm}:${ss}`;
}

export const roundFloat = (
  input?: number,
  decimalPoints = 3
): number | undefined => {
  const multiplier = 10 ** decimalPoints;
  return input !== undefined
    ? Math.round(input * multiplier) / multiplier
    : undefined;
};

export type NoticeProps<OptionType extends OptionTypeBase> = CommonProps<
  OptionType,
  false
> & {
  children: ReactNode;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerProps: { [name: string]: any };
};

export const chunkArray = <T>(arr: T[], chunkSize = 2): T[][] => {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

export const multiplier = 100 / baseValue;

async function loadClientConfig(
  clientType?: FlavorType
): Promise<ClientConfig> {
  try {
    const config = await import(`clients/${clientType}/${clientType}.json`);
    return {
      ...config.default,
    };
  } catch (error) {
    console.error(
      `Error loading configuration for client: ${clientType}`,
      error
    );
    throw error;
  }
}

export default loadClientConfig;

export function formatDatePower(date: Date): string {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const sortDataBasedOnAthleteOrder = <T extends { athleteId: number }>(
  data: T[] | undefined,
  sortedAthletes: Athlete[]
): T[] | undefined => {
  if (!data || !sortedAthletes) return data;
  return data.sort((a, b) => {
    const indexA = sortedAthletes.findIndex(
      (athlete) => athlete.id === a.athleteId
    );
    const indexB = sortedAthletes.findIndex(
      (athlete) => athlete.id === b.athleteId
    );

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
};

// get team based on the falvor
export const getTeamName = () => {
  const flavorType = process.env.REACT_APP_FLAVOR;
  const team = flavorType === FlavorType.adqWomen ? "women" : "men";
  return team;
};

// Convert enum to options
export const convertEnumToOptions = (enumObj: { [key: string]: string }) => {
  return Object?.keys(enumObj)?.map((key) => ({
    label: enumObj[key],
    value: key,
  }));
};

// generate Unix timestamp based on the date in yyyy-mm-dd format
export const convertToUnixTimestamp = (dateString: string): number => {
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(Date.UTC(year, month - 1, day));
  return Math.floor(date.getTime() / 1000);
};

// convert Unix timestamp to date in yyyy-mm-dd format
export const convertUnixTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  return formatDateAPI(date);
};

export const formatDateToYYYYMMDD = (date : any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, '0'); // Pad single digits with leading zeros

  return `${year}-${month}-${day}`;
}

export const severityToOpacity = (severity: string): number => {
  switch (severity) {
    case "MILD":
      return 0.45;
    case "MODERATE":
      return 0.65;
    case "SEVERE":
      return 0.85;
    default:
      return 1;
  }
}

export const getEnumValueForIssue = (enums: any, recordType: string, key: string) => {
if(recordType === "PHYSIO")
  return enums?.bodyLocation[key];
else if(recordType === "ILLNESS")
  return enums?.symptom[key];
else if(recordType === "INJURY")
  return enums?.bodyArea[key];
else
  return key;
}

export const flatpickrCustomOptions =
  {
    dateFormat: "Y-m-d", locale: {
      weekdays: {
        shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      },
      months: {
        shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        longhand: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      },
      firstDayOfWeek: 1,
    },
    shorthandCurrentMonth: true
};

export const checkTabletView = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 1439) {
    return true;
  } else {
    return false;
  }
};

