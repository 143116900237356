import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "hooks/app-hooks";

import { SignIn } from "pages/sign-in/sign-in";
import { Wellness } from "pages/wellness/wellness";
import { Performance } from "pages/performance/performance";
import { ForgotPassword } from "pages/forgot-password/forgot-password";
import { ResetPassword } from "pages/reset-password/reset-password";

import { PrivateRoute } from "components/private-route/private-route";

import { getAccount, getAuthState } from "store/slices/auth";
import { getProfile, getCoachProfile } from "api/profile";
import {
  getAthleteList,
  getCurrentWeekRange,
  setCoachProfile,
  setFilteredWeeklyReportData,
  setFullWeeklyReportData,
  setSelectedIds,
} from "store/slices/shared";
import { Profile, CoachProfile } from "types";
import styles from "./app.module.scss";
import loadClientConfig from "utils";
import { RoleType, FlavorType } from "enums";
import { setClientConfig } from "store/slices/clientConfig";
import { Loading } from "components/loading/loading";
import { AthleteSelection } from "pages/athlete-selection/athlete-selection";
import { Settings } from "pages/settings/settings";
import { GroupSelection } from "pages/group-selection/group-selection";
import TeamDashboard from "pages/team-dashboard/team-dashboard";
import { UploadProvider } from "store/contexts/upload-context";
import "./appInsights/AppInsights";
import { TableView } from "pages/weekly-report/table-view/table-view";
import { ChartView } from "pages/weekly-report/chart-view/chart-view";
import { getWeekNumber } from "pages/weekly-report/utils";
import { getWeeklyReport } from "api/weekly-reports";
import { GroupProfile } from "pages/group/group-profiles/group-profiles";
import { GroupOverView } from "pages/group/group-overview/group-overview";
import { GroupChartView } from "pages/group/group-chart-view/group-chart-view";
import { ChatBot } from "components/chat-bot/chat-bot";
import { Health } from "pages/health/health";
import { HealthDetails } from "pages/health/health-details";
import { MedicalReportTable } from "pages/medical-report/medical-report-table";
import { MedicalReportDetails } from "pages/medical-report/medical-report-details";

export function App() {
  const dispatch = useDispatch();
  const athleteList = useSelector(getAthleteList);
  const selectedWeekRange = useSelector(getCurrentWeekRange);
  const clientConfig = useSelector((state) => state.clientConfig.config);
  const authorised = useSelector(getAuthState);
  const role = useSelector(getAccount);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [coachProfileInfo, setCoachProfileInfo] = useState<CoachProfile | null>(null);
  const [loading, setLoading] = useState(false);

  const memoizedSelectedWeekRange = useMemo(() => selectedWeekRange, [selectedWeekRange]);
  const memoizedAthleteList = useMemo(() => athleteList, [athleteList]);

  useEffect(() => {
    if (!memoizedSelectedWeekRange || !memoizedAthleteList.length || role !== RoleType.coach) {
      return;
    }

    setLoading(true);
    const fetchData = async () => {
      const year = new Date(memoizedSelectedWeekRange.startDate).getFullYear();
      const weekNumber = getWeekNumber(new Date(memoizedSelectedWeekRange.startDate));
      const response = await getWeeklyReport(year, weekNumber);

      const result = response.map((report) => {
        return {
          ...report,
          athlete: memoizedAthleteList.find((athlete) => athlete.id === report.athleteId),
        };
      });

      result.sort((a, b) => {
        if (a.athlete && b.athlete) {
          return a.athlete.lastName.localeCompare(b.athlete.lastName);
        }
        return 0;
      });
      dispatch(setSelectedIds(null));
      dispatch(setFullWeeklyReportData(result));
      dispatch(setFilteredWeeklyReportData(result));
      setLoading(false);
    };

    fetchData();
  }, [memoizedSelectedWeekRange, memoizedAthleteList, dispatch, role]);

  useEffect(() => {
    const clientType = process.env.REACT_APP_FLAVOR as FlavorType;
    loadClientConfig(clientType)
      .then((config) => {
        dispatch(setClientConfig(config));
      })
      .catch((error) => {
        console.error("Failed to load client config:", error);
      });
  }, [dispatch]);

  const getUserProfile = useCallback(async () => {
    const data = await getProfile();
    setProfile(data);
  }, []);

  const getCoachProfileData = useCallback(async () => {
    const data = await getCoachProfile();
    setCoachProfileInfo(data);
  }, []);

  useEffect(() => {
    if (authorised) {
      if (!profile) {
        getUserProfile();
      }

      if (!coachProfileInfo && role === RoleType.coach) {
        getCoachProfileData();
      }

      if (coachProfileInfo) {
        dispatch(setCoachProfile(coachProfileInfo));
      }
    }
  }, [dispatch, profile, getUserProfile, getCoachProfileData, coachProfileInfo, role, authorised]);

  switch (clientConfig === null) {
    case true:
      return <Loading />;
    case false:
      return (
        <>
          <UploadProvider>
            <Helmet>
              <title>{clientConfig?.variables.title}</title>
              <link rel="icon" href={clientConfig?.variables.favicon} />
            </Helmet>
            <BrowserRouter>
              <div className={styles.app}>
                <Switch>
                  <PrivateRoute exact path="/">
                    <Redirect to="/wellness" />
                  </PrivateRoute>
                  <Route path="/sign-in">
                    <SignIn />
                  </Route>
                  <Route path="/forgot-password">
                    <ForgotPassword />
                  </Route>
                  <Route path="/reset-password">
                    <ResetPassword />
                  </Route>
                  <PrivateRoute path="/wellness">
                    <Wellness />
                  </PrivateRoute>
                  <PrivateRoute path="/performance">
                    <Performance />
                  </PrivateRoute>
                  <PrivateRoute path="/health">
                    <Health />
                  </PrivateRoute>
                  <PrivateRoute path="/athlete-selection">
                    <AthleteSelection />
                  </PrivateRoute>
                  <PrivateRoute path="/group-selection">
                    <GroupSelection />
                  </PrivateRoute>
                  <PrivateRoute path="/group-profiles">
                    <GroupProfile loading={false} />
                  </PrivateRoute>
                  <PrivateRoute path="/group-overview">
                    <GroupOverView loading={loading} />
                  </PrivateRoute>
                  <PrivateRoute path="/group-chart-view">
                    <GroupChartView loading={loading} />
                  </PrivateRoute>
                  <PrivateRoute path="/weekly-report-table">
                    <TableView loading={loading} />
                  </PrivateRoute>
                  <PrivateRoute path="/weekly-report-chart">
                    <ChartView loading={loading} />
                  </PrivateRoute>
                  <PrivateRoute path="/team-dashboard">
                    <TeamDashboard />
                  </PrivateRoute>
                  <PrivateRoute path="/settings">
                    <Settings />
                  </PrivateRoute>
                  <PrivateRoute path="/health-details">
                    <HealthDetails />
                  </PrivateRoute>
                  <PrivateRoute path="/medical-report-table">
                    <MedicalReportTable loading={loading} />
                  </PrivateRoute>
                  <PrivateRoute path="/medical-report-details">
                    <MedicalReportDetails />
                  </PrivateRoute>
                </Switch>
                <ChatBot />
              </div>
            </BrowserRouter>
          </UploadProvider>
        </>
      );

    default:
      return <Loading />;
  }
}
